import React from "react";
import { Link } from "react-scroll";
import { ImArrowRight } from "react-icons/im";
import videoDownload from "../assets/portfolio/videoDownload.png";
import quizManager from "../assets/portfolio/quizManager.png";
import reactWeather from "../assets/portfolio/weatherApp.png";

const Portfolio = () => {
  const projects = [
    {
      id: 1,
      name: "React Weather App",
      src: reactWeather,
      demo: "https://dv-weather-app.netlify.app/",
      link: "https://github.com/danilaveselov/weather",
    },
    {
      id: 2,
      name: "YouTube Downloader",
      src: videoDownload,
      link: "https://github.com/danilaveselov/ytdownloader",
    },
    {
      id: 3,
      name: "Quiz Manager Application",
      src: quizManager,
      link: "https://github.com/danilaveselov/exeter-college-quiz-app",
    },
  ];

  return (
    <div
      name="projects"
      className="h-fit pt-20 md:h-screen bg-gradient-to-b from-gray-800 to-black w-full text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-amber-400">
            My Projects
          </p>
          <p className="py-6">Check out some of my projects below</p>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0 pb-8">
          {projects.map(({ id, name, src, demo, link }) => (
            <div
              key={id}
              className="shadow-md shadow-amber-400 rounded-lg flex flex-col justify-between"
            >
              <img
                src={src}
                alt=""
                className="rounded-md duration-200 hover:scale-105"
              />
              <div className="flex flex-col items-center justify-center">
                <p className="text-bold inline border-b-2 border-amber-400 px-6 py-1 m-4 text-center">
                  {name}
                </p>
                <div className="mb-4">
                  {demo && (
                    <a
                      href={demo}
                      target="_blank"
                      rel="noreferrer"
                      className="px-6 py-1 m-4 duration-200 hover:scale-105 text-center rounded-md bg-gradient-to-r from-amber-600 to-amber-400 cursor-pointer"
                    >
                      Demo
                    </a>
                  )}
                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="px-6 py-1 m-4 duration-200 hover:scale-105 text-center rounded-md bg-gradient-to-r from-amber-600 to-amber-400 cursor-pointer"
                  >
                    Code
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <Link
            to="contact"
            smooth
            duration={800}
            className="group text-white w-fit px-6 py-3 my-2 hidden md:flex items-center rounded-md bg-gradient-to-r from-amber-600 to-amber-400 cursor-pointer"
          >
            Contact me
            <span className="ml-3 group-hover:rotate-90 duration-300">
              <ImArrowRight />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
