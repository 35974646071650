import React from "react";
import { Link } from "react-scroll";
import { ImArrowRight } from "react-icons/im";
import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import bootstrap from "../assets/bootstrap.png";
import tailwind from "../assets/tailwind.png";
import python from "../assets/python.png";
import django from "../assets/django.png";
import flask from "../assets/flask.png";
import csharp from "../assets/csharp.png";
import postgresql from "../assets/postgresql.png";
import git from "../assets/git.png";

const Skills = () => {
  const skills = [
    {
      id: 1,
      src: html,
      title: "HTML",
      style: "shadow-orange-500",
    },
    {
      id: 2,
      src: css,
      title: "CSS",
      style: "shadow-blue-700",
    },
    {
      id: 3,
      src: javascript,
      title: "JavaScript",
      style: "shadow-yellow-500",
    },
    {
      id: 4,
      src: reactImage,
      title: "React",
      style: "shadow-blue-400",
    },
    {
      id: 5,
      src: bootstrap,
      title: "Bootstrap",
      style: "shadow-purple-500",
    },
    {
      id: 6,
      src: tailwind,
      title: "Tailwind",
      style: "shadow-sky-400",
    },
    {
      id: 7,
      src: python,
      title: "Python",
      style: "shadow-yellow-400",
    },
    {
      id: 8,
      src: django,
      title: "Django",
      style: "shadow-green-700",
    },
    {
      id: 9,
      src: flask,
      title: "Flask",
      style: "shadow-gray-400",
    },
    {
      id: 10,
      src: csharp,
      title: "C#",
      style: "shadow-purple-600",
    },
    {
      id: 11,
      src: postgresql,
      title: "PostgreSQL",
      style: "shadow-blue-600",
    },
    {
      id: 12,
      src: git,
      title: "Git",
      style: "shadow-orange-700",
    },
  ];

  return (
    <div
      name="skills"
      className="h-fit pt-20 md:h-screen md:pt-0 bg-gradient-to-b from-black to-gray-800 w-full"
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-amber-400 p-2 inline">
            Skills
          </p>
          <p className="py-6">These are the technologies I've worked with</p>
        </div>
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
          {skills.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
            >
              <img src={src} alt={title} className="w-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <Link
            to="projects"
            smooth
            duration={800}
            className="group text-white w-fit px-6 py-3 my-2 hidden md:flex items-center rounded-md bg-gradient-to-r from-amber-600 to-amber-400 cursor-pointer"
          >
            My Projects
            <span className="ml-3 group-hover:rotate-90 duration-300">
              <ImArrowRight />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Skills;
